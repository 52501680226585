<template>
  <div class="Userhome">
    <Userhomeheader :class="Userhomeleftios" />
    <div class="Userhomecenter">
      <Userhomeleft :class="Userhomeleftios" />
      <div class="Userhomeright">
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
import Userhomeheader from "@/components/Userhomeheader.vue";
import Userhomeleft from "@/components/Userhomeleft.vue";
// import Uploadimg from "@/components/Uploadimg.vue";
// import Sjpinglun from "@/components/Sjpinglun.vue";

export default {
  components: {
    Userhomeheader,
    Userhomeleft,
    // Uploadimg,
    // Sjpinglun,
  },
  data() {
    return {
      path: "",
      Userhomeleftios: "",
    };
  },
  created() {
    this.path = this.$route.path;
    if (this.path == "/Userhome") {
      this.Userhomeleftios = "";
    } else {
      this.Userhomeleftios = "Userhomeleftios";
    }
  },
  watch: {
    $route(to, from) {
      this.path = this.$route.path;
    },
    path() {
      if (this.path == "/Userhome") {
        this.Userhomeleftios = "";
      } else {
        this.Userhomeleftios = "Userhomeleftios";
      }
    },
  },
};
</script>
<style>
@import "../assets/css/userhomeheader.css";
</style>
