<template>
  <div class="userhomeheader">
    <img
      class="userhomeheaderimg"
      :src="dataall.cover_img != '0' ? dataall.cover_img : img"
      alt=""
    />
    <div class="userhomeheader_zhe"></div>
    <div class="userhomeheaderbox_dw">
      <div class="userhomeheaderbox_dwcenter">
        <div class="userhomeheaderbox_dwl">
          <div class="userhomeheaderbox_dwlimg">
            <img :src="userpic" alt="" />
          </div>
          <div class="userhomeheaderbox_dwlgz" @click="userhomeheaderbox_dwlgz">
            <p>
              <span>{{ gznum }}</span>
              <span>关注</span>
            </p>
            <p>
              <span>{{ fsnum }}</span>
              <span>粉丝</span>
            </p>
          </div>
        </div>
        <div class="userhomeheaderbox_dwr">
          <div class="userhomeheaderbox_dwrname">
            <p>{{ dataall.username }}</p>
            <p>{{ dataall.username }}简介</p>
          </div>
          <div class="userhomeheaderbox_dwrbtn">
            <el-button icon="el-icon-user" @click="userhome(dataall.userid)"
              >个人主页</el-button
            >
            <el-button icon="el-icon-magic-stick" class="fengmianbtn">
              装扮封面
              <Uploadimg @uploadimg="uploadimgurl" :imgall="imgalls" />
            </el-button>
          </div>
          <ul class="userhomeheaderbox_dwrrz">
            <li>认证信息</li>
            <li @click="rz">
              <div :style="dataall.ismobile == 1 ? iconstyle : ''">
                <icon-svg icon-class="iconshouji54" />
              </div>
            </li>
            <li @click="rz">
              <div :style="dataall.isguara == 1 ? iconstyle : ''">
                <icon-svg icon-class="iconbaozhengjin" />
              </div>
            </li>
            <li @click="rz">
              <div :style="dataall.isemail == 1 ? iconstyle : ''">
                <icon-svg icon-class="iconyouxiang" />
              </div>
            </li>
            <li @click="rz">
              <div :style="dataall.isern == 1 ? iconstyle : ''">
                <icon-svg
                  icon-class="iconshenfenzheng"
                  style="width: 67%; height: 67%; margin: 16%"
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Uploadimg from "@/components/Uploadimg.vue";
export default {
  components: {
    Uploadimg,
  },
  data() {
    return {
      dataall: {},
      iconstyle: "background:#4FC6FF;border-color:#4FC6FF;",
      userinfo: {},
      fsnum: 0,
      gznum: 0,
      img: "https://task.hozoin.cn/Works/20210222/sj-3300420995.png",
      userpic: "https://task.hozoin.cn/Works/20210402/sj-0768621535.png",
      imgalls: {
        name: "",
        width: "1000",
        height: "156",
      },
    };
  },
  methods: {
    userhome(id) {
      this.$router.push({ path: "/Rencai_c", query: { data: id } });
    },
    uploadimgurl(rul) {
      var that = this;
      this.dataall.cover_img = rul;
      this.$axios({
        headers: { "X-CSRF-TOKEN": that.userinfo.token },
        url: "/api/changeCover",
        method: "post",
        data: {
          cover: rul,
        },
      }).then((res) => {
        // this.$message("封面更换成功");
      });
    },
    rz() {
      this.$router.push({
        path: "/Userhome/Userhl_info",
        query: { data: "2" },
      });
    },
    userhomeheaderbox_dwlgz() {
      this.$router.push({
        path: "/Userhome/Userhl_gfc",
      });
    },
    gzfsnum(id) {
      this.$axios({
        url: "/api/getFollowFans",
        method: "post",
        data: {
          userid: this.userinfo.userid,
          f_type: id,
          page: "1",
          limit: "10000",
        },
      }).then((res) => {
        console.log(res);
        if (res.data.code == 1) {
          if (id == 1) {
            this.gznum = res.data.result.count;
          } else {
            this.fsnum = res.data.result.count;
          }
        } else {
          console.log("请求失败");
        }
      });
    },
  },
  created() {
    var that = this;
    this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
    this.tokentimeyz();
    this.$axios({
      headers: { "X-CSRF-TOKEN": that.userinfo.token },
      url: "/api/getUserInfoData",
      method: "post",
      data: {},
    }).then((res) => {
      document.title = res.data.result.username + " 个人中心";
      this.fenxiang(
        document.title,
        res.data.result.username,
        res.data.result.userpic
      );
      that.dataall = res.data.result;
      if (that.dataall.cover_img == null || that.dataall.cover_img == "") {
        that.dataall.cover_img = "0";
      }
      if (that.dataall.userpic != null && that.dataall.userpic != "") {
        that.userpic = that.dataall.userpic;
      }
    });
    this.gzfsnum(1);
    this.gzfsnum(2);
  },
};
</script>
<style>
@import "../assets/css/userhomeheader.css";
</style>
