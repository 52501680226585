<template>
  <div class="Userhomeleft">
    <ul class="Userhomeleftul">
      <li v-if="Userhl_videogl">
        <router-link
          to="/Userhome/Userhl_videogl"
          :style="path == '/Userhome/Userhl_videogl' ? red : black"
        >
          <icon-svg
            icon-class="iconclass"
            style="color: #4fc6ff"
            v-show="path == '/Userhome/Userhl_videogl'"
          />
          <icon-svg
            icon-class="iconclass"
            style="color: #333"
            v-show="path != '/Userhome/Userhl_videogl'"
          />
          <span>管理课程</span>
        </router-link>
      </li>
      <li>
        <router-link
          to="/Userhome/Userhl_info"
          :style="path == '/Userhome/Userhl_info' ? red : black"
        >
          <icon-svg
            icon-class="icondata"
            style="color: #4fc6ff"
            v-show="path == '/Userhome/Userhl_info'"
          />
          <icon-svg
            icon-class="icondata"
            v-show="path != '/Userhome/Userhl_info'"
            style="color: #333"
          />
          <span>完善资料</span>
        </router-link>
      </li>
      <li v-if="Userhl_baoming">
        <router-link
          to="/Userhome/Userhl_baoming"
          :style="path == '/Userhome/Userhl_baoming' ? red : black"
        >
          <icon-svg
            icon-class="icontask"
            style="color: #4fc6ff"
            v-show="path == '/Userhome/Userhl_baoming'"
          />
          <icon-svg
            icon-class="icontask"
            v-show="path != '/Userhome/Userhl_baoming'"
            style="color: #333"
          />
          <span>我的任务</span></router-link
        >
      </li>
      <!-- <li v-if="Userhl_jiaoyi">
        <router-link to="/Userhome/Userhl_jiaoyi"
        :style="path == '/Userhome/Userhl_jiaoyi' ? red : black"
          >
          <icon-svg icon-class="icontask" style="color:#4FC6FF;" v-show="path == '/Userhome/Userhl_jiaoyi'" />
          <icon-svg icon-class="icontask" v-show="path != '/Userhome/Userhl_jiaoyi'" style="color:#333;" />
          <span>交易评价</span></router-link
        >
      </li> -->
      <li>
        <router-link to="/Userhome" :style="path == '/Userhome' ? red : black">
          <icon-svg
            icon-class="iconpurse"
            style="color: #4fc6ff"
            v-show="path == '/Userhome'"
          />
          <icon-svg
            icon-class="iconpurse"
            v-show="path != '/Userhome'"
            style="color: #333"
          />
          <span>我的钱包</span></router-link
        >
      </li>
      <li>
        <router-link
          to="/Userhome/Userhl_dingdan"
          :style="path == '/Userhome/Userhl_dingdan' ? red : black"
        >
          <icon-svg
            icon-class="iconorder"
            style="color: #4fc6ff"
            v-show="path == '/Userhome/Userhl_dingdan'"
          />
          <icon-svg
            icon-class="iconorder"
            v-show="path != '/Userhome/Userhl_dingdan'"
            style="color: #333"
          />
          <span>我的订单</span></router-link
        >
      </li>
      <li v-if="Userhl_zuopin">
        <router-link
          to="/Userhome/Userhl_zuopin"
          :style="path == '/Userhome/Userhl_zuopin' ? red : black"
        >
          <icon-svg
            icon-class="iconwork"
            style="color: #4fc6ff"
            v-show="path == '/Userhome/Userhl_zuopin'"
          />
          <icon-svg
            icon-class="iconwork"
            v-show="path != '/Userhome/Userhl_zuopin'"
            style="color: #333"
          />
          <span>作品管理</span></router-link
        >
      </li>
      <li v-if="Userhl_ygkecheng">
        <router-link
          to="/Userhome/Userhl_ygkecheng"
          :style="path == '/Userhome/Userhl_ygkecheng' ? red : black"
        >
          <icon-svg
            icon-class="iconclass"
            style="color: #4fc6ff"
            v-show="path == '/Userhome/Userhl_ygkecheng'"
          />
          <icon-svg
            icon-class="iconclass"
            v-show="path != '/Userhome/Userhl_ygkecheng'"
            style="color: #333"
          />
          <span>{{ Userhl_ygkecheng_text }}</span></router-link
        >
      </li>
      <li>
        <router-link
          to="/Userhome/Userhl_mezp"
          :style="path == '/Userhome/Userhl_mezp' ? red : black"
        >
          <icon-svg
            icon-class="iconactivity"
            style="color: #4fc6ff"
            v-show="path == '/Userhome/Userhl_mezp'"
          />
          <icon-svg
            icon-class="iconactivity"
            v-show="path != '/Userhome/Userhl_mezp'"
            style="color: #333"
          />
          <span>我的活动</span></router-link
        >
      </li>
      <li>
        <router-link
          to="/Userhome/Userhl_zhanlan"
          :style="path == '/Userhome/Userhl_zhanlan' ? red : black"
        >
          <icon-svg
            icon-class="iconshow"
            style="color: #4fc6ff"
            v-show="path == '/Userhome/Userhl_zhanlan'"
          />
          <icon-svg
            icon-class="iconshow"
            v-show="path != '/Userhome/Userhl_zhanlan'"
            style="color: #333"
          />
          <span>我的展览</span></router-link
        >
      </li>
      <li v-if="Userhl_jianli">
        <router-link
          to="/Userhome/Userhl_jianli"
          :style="path == '/Userhome/Userhl_jianli' ? red : black"
        >
          <icon-svg
            icon-class="iconresume"
            style="color: #4fc6ff"
            v-show="path == '/Userhome/Userhl_jianli'"
          />
          <icon-svg
            icon-class="iconresume"
            v-show="path != '/Userhome/Userhl_jianli'"
            style="color: #333"
          />
          <span>我的简历</span></router-link
        >
      </li>
      <li v-if="Userhl_jianli_q">
        <router-link
          to="/Userhome/Userhl_jianli_q"
          :style="path == '/Userhome/Userhl_jianli_q' ? red : black"
        >
          <icon-svg
            icon-class="iconresume"
            style="color: #4fc6ff"
            v-show="path == '/Userhome/Userhl_jianli_q'"
          />
          <icon-svg
            icon-class="iconresume"
            v-show="path != '/Userhome/Userhl_jianli_q'"
            style="color: #333"
          />
          <span>简历管理</span></router-link
        >
      </li>
      <li>
        <router-link
          to="/Userhome/Userhl_gfc"
          :style="path == '/Userhome/Userhl_gfc' ? red : black"
        >
          <icon-svg
            icon-class="iconfollow"
            style="color: #4fc6ff"
            v-show="path == '/Userhome/Userhl_gfc'"
          />
          <icon-svg
            icon-class="iconfollow"
            v-show="path != '/Userhome/Userhl_gfc'"
            style="color: #333"
          />
          <span>我的关注</span></router-link
        >
      </li>
      <li>
        <router-link
          to="/Userhome/Userhl_shoucang"
          :style="path == '/Userhome/Userhl_shoucang' ? red : black"
        >
          <icon-svg
            icon-class="iconadd"
            style="color: #4fc6ff"
            v-show="path == '/Userhome/Userhl_shoucang'"
          />
          <icon-svg
            icon-class="iconadd"
            v-show="path != '/Userhome/Userhl_shoucang'"
            style="color: #333"
          />
          <span>我的收藏</span></router-link
        >
      </li>
      <li>
        <router-link
          to="/Userhome/Userhl_msg"
          :style="path == '/Userhome/Userhl_msg' ? red : black"
        >
          <icon-svg
            icon-class="iconxiaoxi1"
            style="color: #4fc6ff"
            v-show="path == '/Userhome/Userhl_msg'"
          />
          <icon-svg
            icon-class="iconxiaoxi1"
            v-show="path != '/Userhome/Userhl_msg'"
            style="color: #333"
          />
          <span>消息中心</span></router-link
        >
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      Userhl_jianli: false,
      Userhl_jianli_q: false,
      Userhl_videogl: false,
      Userhl_baoming: false,
      Userhl_jiaoyi: false,
      Userhl_zuopin: false,
      Userhl_ygkecheng: false,
      Userhl_ygkecheng_text: "",
      img:
        "https://task.hozoin.cn/Works/20201104/sj-4369072618.png",
      imgs: "https://task.hozoin.cn/Works/20210112/sj-4064470263.png",
      path: "",
      red: "color:#4FC6FF;border-left:3px solid #4FC6FF;",
      black: "color:#333;border-left:3px solid transparent;",
      userinfo: {},
    };
  },
  created() {
    var that = this;
    var userinfo = JSON.parse(localStorage.getItem("userinfo"));
    this.tokentimeyz();
    this.userinfo = userinfo;
    // userinfo.groupid = 3;
    if (userinfo) {
      console.log(userinfo);
      if (userinfo.groupid == 1) {
        // console.log("我是设计师");
        that.Userhl_jianli = true;
        that.Userhl_baoming = true;
        that.Userhl_jiaoyi = true;
        that.Userhl_zuopin = true;
        that.Userhl_ygkecheng = true;
        that.Userhl_ygkecheng_text = "我的课程";
      } else if (userinfo.groupid == 2) {
        // console.log("我是企业");
        that.Userhl_jianli_q = true;
        that.Userhl_baoming = true;
        that.Userhl_jiaoyi = true;
        that.Userhl_zuopin = true;
        that.Userhl_ygkecheng = true;
        that.Userhl_ygkecheng_text = "我的课程";
      } else if (userinfo.groupid == 3) {
        // console.log("我是导师");
        that.Userhl_videogl = true;
      }
    } else {
      this.$router.push({ path: "/" });
    }
  },
  watch: {
    $route(to, from) {
      this.path = to.path;
      console.log(this.path);
    },
  },
};
</script>
<style>
@import "../assets/css/userhomeheader.css";
</style>
